import React, {useEffect, useState} from 'react';

import UnityPlayerModal from "../components/UnityPlayerModal";

import entrataMostra from './../assets/entrataMostra.jpeg';
import caravaggioIcon from './../assets/caravaggioIcon.png';
import Unity, {UnityContext} from "react-unity-webgl";

const unityContext = new UnityContext({
    loaderUrl: "buildUnity/buildTest.loader.js",
    dataUrl: "buildUnity/buildTest.data",
    frameworkUrl: "buildUnity/buildTest.framework.js",
    codeUrl: "buildUnity/buildTest.wasm",
});


const HomePage = () => {

    const [showUnityModal, setShowUnityModal] = useState(false);
    const [progression, setProgression] = useState(0);


    useEffect(function () {
        unityContext.on("progress", function (progression) {
            setProgression(progression);
        });

    }, []);


    return (
        <div className={'app-container'}>
            <div className={'navBarContainer'}>
                <img src={caravaggioIcon} alt=""/>
                <h1>Mostra del Caravaggio</h1>
            </div>

            <div className={'headingContainer'}>
                Mostra del Caravaggio e dei caravaggeschi <br/>
                Milano, 1951
            </div>
            <div className={'bodyContainer'}>


                <div className="img__wrap" onClick={() => setShowUnityModal(true)}>
                    <img className="img__img" src={entrataMostra} alt="" width="100%" height="100%"/>
                    <p className="img__description">Entra nella mostra</p>
                </div>

                <div className={'descriptionContainer'}>
                    <div>
                        Viene qui riproposta, in parte, una ricostruzione virtuale di quella che è stata, fino ad oggi,
                        la
                        più grande mostra dedicata a
                        Caravaggio.
                        Svoltasi dal 21 aprile al 14 luglio del 1951 nel piano Nobile di Palazzo Reale di Milano. <br/>
                    </div>
                    <div>
                        Clicca sulla foto per accedere alla ricostruzione virtuale.
                    </div>
                </div>
            </div>

            <UnityPlayerModal isOpen={showUnityModal} onCloseModalPress={() => setShowUnityModal(false)}
                              unityContext={unityContext} progression={progression}>
                <Unity unityContext={unityContext} style={{width: "100%", height: "100%", borderRadius: '20px'}}/>
            </UnityPlayerModal>

        </div>
    );
};

export default HomePage;
