import React from 'react';
import Modal from 'react-modal';
import {BounceLoader} from "react-spinners";

Modal.setAppElement(document.getElementById('root'));

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '20px',
        borderWidth: '0',
        minWidth: '250px',
        padding: 0,
        backgroundColor: 'transparent',
        width:'100%',
        height:'100%',
    },
    overlay: {
        backgroundColor: '#6D6D6D80',
        zIndex: 999,
    },
};


const UnityPlayerModal = ({isOpen, onCloseModalPress, unityContext, children, progression}) => {
 /*   const [progression, setProgression] = useState(0);

    useEffect(function () {
        unityContext.on("progress", function (progression) {
            setProgression(progression);
        });
    }, []);*/
    //
    // function handleOnClickFullscreen() {
    //     unityContext.setFullscreen(true);
    // }

    return (
        <Modal isOpen={isOpen} onRequestClose={onCloseModalPress} style={customStyles}>
            {progression < 1 && (
                <div className={'spinner-container'}>
                    <BounceLoader loading={progression < 100} size={100} color={'#A71930'}/>
                    {/*<p>{(progression * 100).toFixed(0)}%</p>*/}
                </div>
            )}
            {children}
        </Modal>
    );
};

export default UnityPlayerModal;
